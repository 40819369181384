import React from "react";

import "../index.css";
import { LoginCard } from "../components/LoginCard";
import { BankWrapper } from "../components/BankWrapper";

export default function Component() {
  return (
    <BankWrapper>
      <LoginCard
        logoSrc="/assets/images/sycamore-bank-wordmark.png"
        homePath="/home"
        regPath="/register"
      />
    </BankWrapper>
  );
}
