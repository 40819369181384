import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "@emotion/styled";
import { Card, TextField, Button } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import { navigate } from "gatsby";
import { AutofillContext } from "../contexts/AutofillContext";
import { BusinessContext } from "../contexts/BusinessContext";
import { ErrorText } from "../styled";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CardContainer = styled(Card)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #ffffff;
  border-radius: 8px;

  > * {
    margin-bottom: 1rem;
  }

  > hr {
    width: 100%;
  }
`;

const LogoImg = styled.img`
  height: 10rem;
`;

export const LoginCard = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [hasError, setHasError] = useState(false);
  const { setCallback, clearCallback } = useContext(AutofillContext);
  const { wordmark } = useContext(BusinessContext);

  const autofillForm = useCallback(() => {
    setEmail("johndoe1@email.com");
    setPw("fakepassword");
  }, []);

  useEffect(() => {
    setCallback(autofillForm);

    return () => {
      clearCallback();
    };
  }, [setCallback, autofillForm, clearCallback]);

  const valid = useMemo(() => EMAIL_REGEX.test(email) && pw.length >= 8, [
    email,
    pw,
  ]);

  const onLogIn = useCallback(() => {
    if (!valid) {
      setHasError("Please enter valid credentials.");
    } else {
      setHasError(null);

      fetch(`/api/v1/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          pw,
        }),
      })
        .then((response) => {
          if (response.ok) {
            navigate(`/home`);
          } else {
            throw new Error("Failed to log in.");
          }
        })
        .catch(() => setHasError("Failed to log in."));
    }
  }, [valid, email, pw]);

  return (
    <CardContainer>
      <LogoImg src={wordmark} />
      <TextField
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        type="email"
      />
      <TextField
        fullWidth
        value={pw}
        onChange={(e) => setPw(e.target.value)}
        label="Password"
        type="password"
      />
      {hasError ? <ErrorText>{hasError}</ErrorText> : null}
      <Button
        fullWidth
        onClick={onLogIn}
        variant="contained"
        color="primary"
        style={{ marginTop: "1rem" }}
        size="large"
        disabled={!valid}
      >
        Log In
      </Button>
      <Link>Need help logging in?</Link>
      <hr />
      <Link to={"/register"}>Sign up for an account</Link>
    </CardContainer>
  );
};
